import { createStyles, Theme } from '@nelnet/unifi-components-react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const styles = ({ breakpoints }: Theme) =>
  createStyles({
    boldDivider: {
      backgroundColor: '#73777F',
    },
    paragraph: {
      textAlign: 'left',
      '& img': {
        display: 'flex',
        maxWidth: '275px',
        margin: '1rem auto 0',
        [breakpoints.up('sm')]: {
          maxWidth: '500px',
        },
        [breakpoints.up('md')]: {
          maxWidth: '625px',
          margin: '1rem',
        },
        [breakpoints.up('lg')]: {
          maxWidth: '850px',
        },
      },
    },
    standardDivider: {
      '&:last-child': {
        display: 'none',
      },
    },
  });

export default styles;
