import * as React from 'react';

import { RichText } from 'prismic-reactjs';

import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Paper,
  Typography,
  withStyles,
  WithStyles,
} from '@nelnet/unifi-components-react';

import styles from './styles';
import MiscellaneousSection from '../MiscellaneousSection';

interface IPersonasSectionProps extends WithStyles<typeof styles> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  list_items?: Array<any>;
}

const PersonasSection: React.FunctionComponent<IPersonasSectionProps> = ({
  classes,
  list_items,
}) => {
  const content_body = list_items[0].node.body;
  if (content_body.length != 0 && content_body != null && content_body != undefined) {
    return (
      <Paper>
        <Grid container className={classes.direction}>
          <Grid item xs={12} className={classes.container}>
            <MiscellaneousSection content_items={list_items}></MiscellaneousSection>
          </Grid>
        </Grid>
        {content_body.map((item, i) => {
          const intro_content = item.primary;
          const persona_content = item.fields;
          const type = item.label;
          return (
            <div key={i}>
              {type == 'persona_content' ? (
                <>
                  <Grid container className={classes.direction}>
                    <Grid item xs={12} className={classes.section}>
                      {intro_content.intro_title ? (
                        <Typography variant="h3" align="left" className="regular">
                          {RichText.asText(intro_content.intro_title)}
                        </Typography>
                      ) : null}
                      {intro_content.intro_description ? (
                        <Typography variant="body1" align="left">
                          {RichText.asText(intro_content.intro_description)}
                        </Typography>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container className={classes.cardSection}>
                    {persona_content.map((persona_item, i) => {
                      const persona_role = persona_item.persona_role[0].text;
                      const persona_product = persona_item.persona_product[0].text;
                      const persona_image = persona_item.persona_image;
                      const persona_last_updated = persona_item.last_updated_date[0].text;
                      const persona_description = persona_item.persona_description[0].text;
                      return (
                        <Grid item xs={12} md={6} className={classes.cardItems} key={i}>
                          <Card className={classes.card}>
                            <CardHeader
                              avatar={
                                <Avatar
                                  size="large"
                                  alt={persona_image.alt}
                                  src={persona_image.url}
                                />
                              }
                              title={persona_role}
                              titleTypographyProps={{
                                variant: 'h4',
                                className: 'regular',
                                align: 'left',
                              }}
                              subheader={persona_product}
                            />
                            <CardContent>
                              <Typography align="left">
                                Last Updated: {persona_last_updated}
                              </Typography>
                              <Typography align="left">{persona_description}</Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Divider margin={{ bottom: 2 }} className={classes.standardDivider} />
                </>
              ) : null}
              {type == 'additional_content' ? (
                <Grid container className={classes.direction}>
                  <Grid item xs={12} className={classes.additionalSection}>
                    {item.primary.additional_title ? (
                      <Typography variant="h3" align="left" className="regular">
                        {RichText.asText(item.primary.additional_title)}
                      </Typography>
                    ) : null}
                    {item.primary.additional_description ? (
                      <Typography variant="body1" align="left">
                        {RichText.render(intro_content.additional_description)}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              ) : null}
            </div>
          );
        })}
      </Paper>
    );
  } else {
    return null;
  }
};

export default withStyles(styles)(PersonasSection);
