import * as React from 'react';

import { RichText } from 'prismic-reactjs';

import { Divider, Typography, withStyles, WithStyles } from '@nelnet/unifi-components-react';

import styles from './styles';

interface IMiscellaneousSectionProps extends WithStyles<typeof styles> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content_items?: Array<any>;
}

const MiscellaneousSection: React.FunctionComponent<IMiscellaneousSectionProps> = ({
  classes,
  content_items,
}) => {
  const miscellaneous_content = content_items[0].node.miscellaneous_content;
  const miscellaneous_content_body = miscellaneous_content[0]
    ? miscellaneous_content[0].content_body
    : null;
  if (miscellaneous_content.length != 0 && miscellaneous_content_body != null) {
    return (
      <>
        {miscellaneous_content.map((miscellaneous_item, i) => {
          return (
            <div key={i}>
              {miscellaneous_item.content_title ? (
                <>
                  <Typography variant="h2" align="left" className="lowercase">
                    {RichText.asText(miscellaneous_item.content_title)}
                  </Typography>
                  <Divider className={classes.boldDivider} />
                </>
              ) : null}
              <div className={classes.paragraph}>
                {RichText.render(miscellaneous_item.content_body)}
              </div>
              <Divider margin={{ bottom: 2 }} className={classes.standardDivider} />
            </div>
          );
        })}
      </>
    );
  } else {
    return null;
  }
};

export default withStyles(styles)(MiscellaneousSection);
