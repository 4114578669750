import * as React from 'react';
import { RichText, RichTextBlock } from 'prismic-reactjs';

import {
  AppBar,
  Grid,
  Link,
  Toolbar,
  Typography,
  WithStyles,
  withStyles,
} from '@nelnet/unifi-components-react';
import styles from './styles';

interface IBreadcrumbsProps extends WithStyles<typeof styles> {
  title?: RichTextBlock[];
  title_url?: string;
  subtitle?: RichTextBlock[];
  subtitle_url?: string;
  tertiary_title?: RichTextBlock[];
  page_color?: string;
  page_font_color?: string;
}

const Breadcrumbs: React.FunctionComponent<IBreadcrumbsProps> = ({
  classes,
  title,
  title_url,
  subtitle,
  subtitle_url,
  tertiary_title,
  page_color,
  page_font_color,
}) => {
  const url = title_url.slice(0, -1);
  return (
    <AppBar position="static">
      <Toolbar style={{ backgroundColor: `${page_color}` }}>
        <Grid container className={classes.breadcrumbs}>
          <Grid item>
            <Link
              underline="hover"
              href={`${url}/`}
              onClick={() => window.scrollTo(0, 0)}
              style={{ color: `${page_font_color}` }}
            >
              {RichText.asText(title)}
            </Link>
          </Grid>
          <Grid item padding={{ right: 1, left: 1 }} style={{ color: `${page_font_color}` }}>
            /
          </Grid>
          {tertiary_title ? (
            <>
              <Grid item>
                <Link
                  underline="hover"
                  href={`${url}${subtitle_url}`}
                  onClick={() => window.scrollTo(0, 0)}
                  style={{ color: `${page_font_color}` }}
                >
                  {RichText.asText(subtitle)}
                </Link>
              </Grid>
              <Grid item padding={{ right: 1, left: 1 }} style={{ color: `${page_font_color}` }}>
                /
              </Grid>
              <Grid item className={classes.breadcrumbs}>
                <Typography align="left" style={{ color: `${page_font_color}` }}>
                  {RichText.asText(tertiary_title)}
                </Typography>
              </Grid>
            </>
          ) : (
            <Grid item className={classes.breadcrumbs}>
              <Typography align="left" style={{ color: `${page_font_color}` }}>
                {RichText.asText(subtitle)}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(Breadcrumbs);
