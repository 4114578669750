import { createStyles, Theme } from '@nelnet/unifi-components-react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const styles = ({ palette, breakpoints, spacing }: Theme) =>
  createStyles({
    card: {
      //TODO: figure out why this is grouchy... but still manages to set the background color correctly?
      // this same thing is happening on the background page
      backgroundColor: palette.background.main,
      display: 'flex-wrap',
      width: '100%',
      padding: `${spacing(2)}px ${spacing(2)}px`,
      [breakpoints.up('md')]: {
        padding: `${spacing(5)}px ${spacing(8)}px`,
        display: 'inline-flex',
      },
      '& div > h1': {
        [breakpoints.down('xs')]: {
          fontSize: '2rem',
        },
      },
    },
    icon: {
      height: '125px',
      width: '125px',
      margin: '0.5rem auto',
    },
  });

export default styles;
