import { createStyles } from '@nelnet/unifi-components-react';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const styles = () =>
  createStyles({
    breadcrumbs: {
      '& div > a,p': {
        fontSize: '0.8125rem',
        lineHeight: 1,
        letterSpacing: '2px',
        textTransform: 'uppercase',
      },
      '& div > p': {
        marginTop: '0.35rem',
      },
    },
  });

export default styles;
