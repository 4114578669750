import * as React from 'react';
import { RichText, RichTextBlock } from 'prismic-reactjs';

import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  WithStyles,
  withStyles,
} from '@nelnet/unifi-components-react';
import styles from './styles';

interface IDefinitionHeaderProps extends WithStyles<typeof styles> {
  icon_url?: string;
  icon_alt?: string;
  page_title: RichTextBlock[];
  summary?: RichTextBlock[];
  title?: RichTextBlock[];
}

const DefinitionHeader: React.FunctionComponent<IDefinitionHeaderProps> = ({
  classes,
  icon_url,
  icon_alt,
  page_title,
  summary,
  title,
}) => {
  return (
    <Grid container>
      <Card className={classes.card}>
        <Grid item xs={12} md={2}>
          <CardMedia image={icon_url} title={icon_alt} className={classes.icon} />
        </Grid>
        <Grid item xs>
          <CardContent>
            {title ? (
              <Typography align="left" variant="h1">
                {`${page_title[0].text} ${title[0].text}`}
              </Typography>
            ) : (
              <Typography align="left" variant="h1">
                {RichText.asText(page_title)}
              </Typography>
            )}
            <Typography variant="body1" align="left" margin={{ top: 1 }} className="small">
              {RichText.asText(summary)}
            </Typography>
          </CardContent>
        </Grid>
      </Card>
    </Grid>
  );
};

export default withStyles(styles)(DefinitionHeader);
