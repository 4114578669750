import { createStyles, Theme } from '@nelnet/unifi-components-react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const styles = ({ breakpoints, spacing, palette }: Theme) =>
  createStyles({
    container: {
      backgroundColor: palette.background.paper,
      position: 'relative',
      margin: `${spacing(3)}px`,
      borderBottom: '3px solid rgba(0, 0, 0, 0.12)',
      [breakpoints.up('md')]: {
        padding: '2.5rem 0 1rem',
        margin: `0 ${spacing(8)}px ${spacing(1)}px`,
      },
    },
    direction: {
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      padding: `0 ${spacing(1)}px`,
      [breakpoints.up('md')]: {
        flexWrap: 'nowrap',
        padding: `0 ${spacing(4)}px`,
      },
    },
    section: {
      backgroundColor: palette.background.paper,
      position: 'relative',
      margin: `${spacing(3)}px`,
      [breakpoints.up('md')]: {
        margin: `${spacing(3)}px ${spacing(8)}px ${spacing(2)}px`,
      },
    },
    cardSection: {
      alignItems: 'stretch',
      padding: `0 ${spacing(1)}px`,
      [breakpoints.up('md')]: {
        padding: `0 ${spacing(10)}px`,
      },
    },
    cardItems: {
      padding: `0 ${spacing(3)}px`,
      [breakpoints.up('md')]: {
        padding: `${spacing(2)}px`,
      },
    },
    additionalSection: {
      backgroundColor: palette.background.paper,
      position: 'relative',
      margin: `${spacing(3)}px`,
      [breakpoints.up('md')]: {
        margin: `${spacing(2)}px ${spacing(8)}px ${spacing(8)}px`,
      },
    },
    standardDivider: {
      margin: `${spacing(2)}px 2rem ${spacing(1)}px`,
      [breakpoints.up('md')]: {
        margin: `${spacing(4)}px 6rem ${spacing(1)}px`,
      },
    },
    card: {
      padding: '1rem 0.5rem 0.5rem',
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      borderRadius: '4px',
      marginBottom: '1rem',
      [breakpoints.up('md')]: {
        height: '100%',
        marginBottom: 0,
      },
    },
  });

export default styles;
