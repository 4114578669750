import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { RichText, RichTextBlock } from 'prismic-reactjs';

import { graphql } from 'gatsby';

import LayoutFrame from '../components/layout/LayoutFrame';
import { Typography } from '@nelnet/unifi-components-react';
import CalloutGroup from '../components/layout/CalloutGroup';
import DefinitionHeader from '../components/layout/DefinitionHeader';
import Breadcrumbs from '../components/layout/Breadcrumbs';
import PersonasSection from '../components/layout/PersonasSection';

interface IPersonasProps {
  data: {
    prismic: {
      allCapabilitiess: {
        edges: [
          {
            node: {
              _meta: {
                uid: string;
                tags: string;
              };
              title: RichTextBlock[];
            };
          },
        ];
      };
      allPersonass: {
        edges: [
          {
            node: {
              _meta: {
                uid: string;
                tags: string;
              };
              title: RichTextBlock[];
              parent_page: RichTextBlock[];
              parent_page_uid: RichTextBlock[];
              primary_page_color: string;
              page_font_color: string;
              header_content: RichTextBlock[];
              image: {
                url: string;
                alt: string;
              };
              miscellaneous_content: {
                content_title: string;
                content_body: {
                  text: string;
                  image: {
                    url: string;
                    alt: string;
                  };
                };
              };
              body: {
                primary: {
                  intro_title: RichTextBlock[];
                  intro_description: RichTextBlock[];
                  additional_title: RichTextBlock[];
                  additional_description: RichTextBlock[];
                };
                fields: {
                  persona_product: RichTextBlock[];
                  persona_role: RichTextBlock[];
                  last_updated_date: RichTextBlock[];
                  persona_description: RichTextBlock[];
                  persona_image: {
                    url: string;
                    alt: string;
                  };
                };
                label: RichTextBlock[];
              };
            };
          },
        ];
      };
      allFooters: {
        edges: [
          {
            node: {
              title: RichTextBlock[];
              subtitle: RichTextBlock[];
              button_text: RichTextBlock[];
              button_style: 'text' | 'outlined' | 'contained';
              button_link: {
                text: string;
              };
            };
          },
        ];
      };
    };
  };
}

const Personas: React.FunctionComponent<IPersonasProps> = ({ data }) => {
  // Required check for no data being returned
  const doc = data.prismic.allPersonass.edges.slice(0, 1).pop();
  const parentDoc = data.prismic.allCapabilitiess.edges.slice(0, 1).pop();
  const footerDoc = data.prismic.allFooters.edges.slice(0, 1).pop();

  const uid = parentDoc.node._meta.uid;
  const details_uid = doc.node.parent_page_uid[0].text;
  const sub_details_uid = doc.node._meta.uid;
  const page_title = doc.node.title;

  if (!doc) {
    return (
      <LayoutFrame currPath={`/${uid}/${details_uid}/${sub_details_uid}/`}>
        <Helmet>
          <title>{RichText.asText(page_title)}</title>
        </Helmet>
        <Typography variant="h2" margin={{ bottom: 3 }}>
          {RichText.asText(page_title)}
        </Typography>
      </LayoutFrame>
    );
  }

  const page_font_color = doc.node.page_font_color.slice(-7);
  const page_color = doc.node.primary_page_color.slice(-7);

  const title = parentDoc.node.title;
  const subtitle = doc.node.parent_page;

  const icon_url = doc.node.image.url;
  const icon_alt = doc.node.image.alt;
  const header_content = doc.node.header_content;

  const list_items = data.prismic.allPersonass.edges;

  const { button_text, button_style } = footerDoc.node;
  const footer_title = footerDoc.node.title;
  const footer_subtitle = footerDoc.node.subtitle;
  const button_link = footerDoc.node.button_link[0].text;

  return (
    <LayoutFrame currPath={`/${uid}/${details_uid}/${sub_details_uid}/`} key={sub_details_uid}>
      <Helmet>
        <title>{RichText.asText(page_title)}</title>
      </Helmet>
      <Breadcrumbs
        title={title}
        title_url={`/${uid}/`}
        subtitle={subtitle}
        subtitle_url={`/${details_uid}/`}
        tertiary_title={page_title}
        page_color={page_color}
        page_font_color={page_font_color}
      ></Breadcrumbs>
      <DefinitionHeader
        icon_url={icon_url}
        icon_alt={icon_alt}
        page_title={page_title}
        summary={header_content}
      ></DefinitionHeader>
      <PersonasSection list_items={list_items}></PersonasSection>
      <CalloutGroup
        title={footer_title}
        subtitle={footer_subtitle}
        button_text={button_text}
        button_style={button_style}
        button_url={button_link}
      ></CalloutGroup>
    </LayoutFrame>
  );
};

export default Personas;

export const query = graphql`
  query PersonasQuery {
    prismic {
      allCapabilitiess {
        edges {
          node {
            _meta {
              uid
            }
            title
          }
        }
      }
      allPersonass {
        edges {
          node {
            _meta {
              uid
              tags
            }
            title
            parent_page
            parent_page_uid
            primary_page_color
            page_font_color
            header_content
            image
            miscellaneous_content {
              content_title
              content_body
            }
            body {
              ... on PRISMIC_PersonasBodyPersona {
                primary {
                  intro_title
                  intro_description
                }
                fields {
                  persona_product
                  persona_role
                  last_updated_date
                  persona_description
                  persona_image
                }
                label
              }
              ... on PRISMIC_PersonasBodyAdditional_content {
                primary {
                  additional_title
                  additional_description
                }
                label
              }
            }
          }
        }
      }
      allFooters {
        edges {
          node {
            title
            subtitle
            button_text
            button_style
            button_link
          }
        }
      }
    }
  }
`;
